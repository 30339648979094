// script-loader.service.ts
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadScript(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if(document.querySelector(`script[src="${url}"]`)) {
        resolve();
        return;
      }

      // Create a new script element
      const script = document.createElement('script');
      script.src = url;
      script.type = 'text/javascript';
      script.async = true;
      //s.parentNode.insertBefore(script, s)
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script ${url}`));

      // Append the script to the document head
      document.head.appendChild(script);
    })
  }

  removeScript(url: string): void {
    const scripts = document.querySelectorAll(`script[src="${url}"]`);
    scripts.forEach(script => this.renderer.removeChild(document.body, script));
  }
}
