/* eslint-disable no-useless-catch */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { LoadingStateService } from '@BaseServices/loading-state.service';
import { MessageStateService } from '@BaseServices/message-state.service';
import { APIResult } from '@Models/api/api-result';
import { Observable } from 'rxjs';

export type SearchResult<T extends object = object> = {
  TotalRecords: number;
  Results: T[];
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  protected site = environment.API_URL;
  protected apiEndPoint = this.site;
  protected _loadingService: any;
  protected _alertService: MessageStateService;

  constructor(
    protected http: HttpClient,
    private loading_Service: LoadingStateService,
    private alertService: MessageStateService,
  ) {
    this._loadingService = loading_Service;
    this._alertService = alertService;
  }

  public SetBaseURL(apiURL: string) {
    this.apiEndPoint = apiURL;
  }

  handleResponse(res: APIResult): any {
    if (res) {
      if (res.Success) {
        this._alertService.ShowSuccess('Success!');
      }
      else {
        this._alertService.ShowError(res.ErrorMessage || 'There was an Error Processing your request');
      }
    }
  }

  create<T = any>(path: string, requestBody: any): Observable<T> {
    this.loading_Service.setStatus(true);
    return this.http.post<T>(this.apiEndPoint + path, requestBody).pipe(
      map((res) => {
        try {
          this.handleResponse(res);
          this.loading_Service.setStatus(false);
          return res;
        } catch (err) {
          this._alertService.ShowError(err.ErrorMessage || 'Error Creating Item');
          this.loading_Service.setStatus(false);
          throw err;
        }
      }),
    );
  }

  read<T = any>(path: string, queryParams?: any): Observable<T> {
    if (queryParams) {
      return this.http.get<T>(this.apiEndPoint + path, { params: queryParams }).pipe(
        map((res) => {
          try {
            return res;
          } catch (err) {
            this._alertService.ShowError(err.ErrorMessage || 'Error Loading Data');
            throw err;
          }
        }),
      );
    } else {
      return this.http.get<T>(this.apiEndPoint + path).pipe(
        map((res) => {
          try {
            return res;
          } catch (err) {
            this._alertService.ShowError(err.ErrorMessage || 'Error Loading Data');
            throw err;
          }
        }),
      );
    }
  }

  readBlobDataByID(path: string, id: number): Observable<Blob> {
    return this.http.get(this.apiEndPoint + path + `/${id}`, { headers: { 'Content-type': 'application/json' }, responseType: 'blob' }).pipe(
      map((res) => {
        try {
          return res;
        } catch (err) {
          this._alertService.ShowError(err.ErrorMessage || 'Error Loading Data');
          throw err;
        }
      }),
    );
  }

  readByID<T = any>(path: string, id: number, args?: any): Observable<T> {
    if (id) {
      return this.http.get<T>(this.apiEndPoint + path + `/${id}`, { params: args }).pipe(
        map((res) => {
          try {
            return res;
          } catch (err) {
            this._alertService.ShowError(err.ErrorMessage || 'Error Loading Data');
            throw err;
          }
        }),
      );
    } else {
      return this.http.get<T>(this.apiEndPoint + path).pipe(
        map((res) => {
          try {
            return res;
          } catch (err) {
            this._alertService.ShowError(err.ErrorMessage || 'Error Loading Data');
            throw err;
          }
        }),
      );
    }
  }

  search<T extends object = any>(path: string, requestBody: any): Observable<T> {
    return this.http.post<T>(this.apiEndPoint + path, requestBody).pipe(
      map((res) => {
        try {
          return res;
        } catch (err) {
          this._alertService.ShowError(err.ErrorMessage || 'Error Loading Data');
          throw err;
        }
      }),
    );
  }

  translate<T = any>(path: string, requestBody: any): Observable<T> {
    return this.http.post<T>(this.apiEndPoint + path, requestBody).pipe(
      map((res) => {
        try {
          return res;
        } catch (err) {
          this._alertService.ShowError(err.ErrorMessage || 'Error Loading Data');
          throw err;
        }
      }),
    );
  }

  update<T = any>(path: string, requestBody: any): Observable<T> {
    this.loading_Service.setStatus(true);

    return this.http.put<T>(this.apiEndPoint + path, requestBody).pipe(
      map((res) => {
        try {
          this.handleResponse(res);
          this.loading_Service.setStatus(false);
          return res;
        } catch (err) {
          this.loading_Service.setStatus(false);
          this._alertService.ShowError(err.ErrorMessage || 'Error Updating Record');
          throw err;
        }
      }),
    );
  }

  delete<T = any>(path: string, id: any): Observable<T> {
    this.loading_Service.setStatus(true);

    return this.http.delete<T>(this.apiEndPoint + path + `/${id}`).pipe(
      map((res) => {
        try {
          this.handleResponse(res);
          this.loading_Service.setStatus(false);
          return res;
        } catch (err) {
          this.loading_Service.setStatus(false);
          this._alertService.ShowError(err.ErrorMessage || 'Error Deleting Record');
          throw err;
        }
      }),
    );
  }

  deleteObj<T = any>(path: string, obj: any): Observable<T> {
    this.loading_Service.setStatus(true);

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: obj,
    };

    return this.http.delete<T>(this.apiEndPoint + path + '/', options).pipe(
      map((res) => {
        try {
          this.handleResponse(res);
          this.loading_Service.setStatus(false);
          return res;
        } catch (err) {
          this.loading_Service.setStatus(false);
          this._alertService.ShowError(err.ErrorMessage || 'Error Deleting Record');
          throw err;
        }
      }),
    );
  }

  login<T = any>(path: string, requestBody: any): Observable<T> {
    this.loading_Service.setStatus(true);
    return this.http.post<T>(this.apiEndPoint + path, requestBody).pipe(
      map((res) => {
        try {
          this.loading_Service.setStatus(false);
          return res;
        } catch (err) {
          this._alertService.ShowError(err.ErrorMessage || 'Error Creating Item');
          this.loading_Service.setStatus(false);
          throw err;
        }
      }),
    );
  }

  public static Api_Url(): string {
    return environment.API_URL;
  }
}
