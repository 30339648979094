<!--Main Navbar-->
<mat-toolbar color="primary" class="shadow-md">
  <mat-toolbar-row>
    <div [ngClass]="{ ' w-6/12': user, 'inline-block w-6/12': !user }">
      <a mat-button routerLinkActive="activeRoute" [routerLinkActiveOptions]="{ exact: true }" routerLink="" [ngClass]="isCurrentEditPage('passwordreset') ? 'disabled-link' : ''">
        <mat-icon>home</mat-icon>
        <span class="px-1 hidden lg:inline-block">Home</span>
      </a>
    </div>
    <div class="w-6/12">
      <ng-container *ngIf="user">
        <a mat-button
           routerLinkActive="activeRoute"
           routerLink="/customers"
           *ngIf="this.authStateService.UserPermissionAllowed([UserRoles.SuperAdmin, UserRoles.CustomerAdmin, UserRoles.CustomerReadOnly]) && this.user.IsInternalUser"
           [ngClass]="isCurrentEditPage('passwordreset') ? 'disabled-link' : ''">
          <mat-icon>recent_actors</mat-icon>
          <span class="px-1 hidden lg:inline-block">Customers</span>
        </a>
        <a mat-button
           routerLinkActive="activeRoute"
           routerLink="/lessons"
           *ngIf="this.authStateService.UserPermissionAllowed([UserRoles.SuperAdmin, UserRoles.LessonAdmin, UserRoles.LessonReadOnly, UserRoles.LessonRestricted]) && this.user.IsInternalUser"
           [ngClass]="isCurrentEditPage('passwordreset') ? 'disabled-link' : ''">
          <mat-icon>school</mat-icon>
          <span class="px-1 hidden lg:inline-block">Lessons</span>
        </a>

        <!-- Customer Packages Module-->
        <a mat-button
           routerLinkActive="activeRoute"
           routerLink="/customerpackages"
           *ngIf="this.authStateService.UserPermissionAllowed([5])"
           [ngClass]="isCurrentEditPage('passwordreset') ? 'disabled-link' : ''">
          <mat-icon>inventory_2</mat-icon>
          <span class="px-1 hidden lg:inline-block">Packages</span>
        </a>

        <a mat-button
           routerLinkActive="activeRoute"
           routerLink="/resources"
           *ngIf="authStateService.UserPermissionAllowed([UserRoles.SuperAdmin, UserRoles.ResourcesAdmin, UserRoles.ResourcesReadOnly]) && this.user.IsInternalUser"
           [ngClass]="isCurrentEditPage('passwordreset') ? 'disabled-link' : ''">
          <mat-icon>group</mat-icon>
          <span class="px-1 hidden lg:inline-block">Resources</span>
        </a>

        <a mat-button
           routerLinkActive="activeRoute"
           routerLink="/reports"
           *ngIf="this.authStateService.UserPermissionAllowed([UserRoles.SuperAdmin, UserRoles.Reports]) && this.user.IsInternalUser"
           [ngClass]="isCurrentEditPage('passwordreset') ? 'disabled-link' : ''">
          <mat-icon>insert_chart</mat-icon>
          <span class="px-1 hidden lg:inline-block">Reports</span>
        </a>

        <a mat-button
           routerLinkActive="activeRoute"
           routerLink="/admin/admins"
           *ngIf="this.authStateService.UserPermissionAllowed([UserRoles.SuperAdmin]) && this.user.IsInternalUser"
           [ngClass]="isCurrentEditPage('passwordreset') ? 'disabled-link' : ''">
          <mat-icon>admin_panel_settings</mat-icon>
          <span class="px-1 hidden lg:inline-block">Admin</span>
        </a>

        <a mat-button
           routerLinkActive="activeRoute"
           routerLink="/customer-portal"
           *ngIf="!this.user.IsInternalUser"
           [ngClass]="isCurrentEditPage('passwordreset') ? 'disabled-link' : ''">
          <mat-icon>store</mat-icon>
          <span class="px-1 hidden lg:inline-block">Customer Portal</span>
        </a>

      </ng-container>
    </div>

    <div class="flex justify-end" style="text-decoration: none; border: none"></div>

    <button mat-button routerLinkActive="activeRoute" routerLink="/login" *ngIf="!user">
      <mat-icon>person</mat-icon>
      <span class="p-2 hidden lg:inline">Login</span>
    </button>

    <div class="flex justify-end w-6/12 m-2" *ngIf="user" routerLinkActive="activeRoute" style="text-decoration: none; border: none">
      <button mat-raised-button [matMenuTriggerFor]="menu" matBadgeColor="primary">
        {{ user.userName }}
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="resetPassword()">
          <mat-icon>key</mat-icon>
          Reset Password
        </button>
        <button mat-menu-item (click)="onLogout()">
          <mat-icon>logout</mat-icon>
          Logout
        </button>
      </mat-menu>
    </div>
    <!-- Notification Badge -->
    <button
      mat-raised-button
      color="primary"
      matBadge="{{ this.notifications.length }}"
      matBadgePosition="after"
      style="background-color: red"
      *ngIf="this.notifications.length >= 1 && user"
      [matMenuTriggerFor]="notificationMenu"
    >
      <mat-icon>notifications</mat-icon>
    </button>
    <mat-menu #notificationMenu="matMenu" class="notifications">
      <span style="font-size: medium; padding: 5px; display: block; color: grey; text-align: center; border-bottom: 1px solid #aaa">System</span>
      <mat-list>
        <ng-container *ngFor="let item of notifications">
          <button mat-menu-item class="whitespace-pre-wrap leading-4 pb-4">
            <mat-icon>notifications</mat-icon>
            <span>{{ item.Description }} {{ item.NotificationDate | date : 'MM/dd/yyyy' }}</span>
          </button>
        </ng-container>
      </mat-list>
      <br />
      <ng-container *ngIf="this.userNotifications.length > 0">
        <span style="font-size: medium; padding: 5px; display: block; color: grey; text-align: center; border-bottom: 1px solid #aaa">User</span>
        <mat-list>
          <button mat-menu-item style="text-align: center">
            <span>No Notifications</span>
          </button>
        </mat-list>
      </ng-container>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
